@import "../Shared/colors.scss";

.dev-c-article-card {
  --ads-card-background-color: var(--white, #fff);
  --ads-card-border-color: var(--arm-light-grey, #e5eceb);
  --ads-card-border-radius: 10px;
  --ads-card-border-style: solid;
  --ads-card-border-width: 1px;
  overflow: hidden;
  height: 250px;

  p {
    font-size: 16px;
    font-weight: 400em;

    // Restrict description to 2 lines and add ellipses
    line-height: 1.5rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /*! autoprefixer: ignore next */
    -webkit-box-orient: vertical;
  }

  @media (max-width: 768px) {
    p {
      width: 16rem;
    }
  }
}

.dev-c-article-card:hover {
  --ads-card-background-color-hover: var(--white, #fff);
  --ads-card-border-color-hover: var(--arm-web-safe-orange, #ff6b00);
  --ads-card-box-shadow-hover: rgba(0, 0, 0, 0.16) 0 3px 6px 1px;
  transition: color, border-color 0.3s ease-in-out;
}

html[theme="dark"] .dev-c-article-card {
  --ads-card-background-color: var(--arm-color-base, #1f2023);
  --ads-card-border-color: #000;
}

html[theme="dark"] .dev-c-article-card:hover {
  --ads-card-background-color-hover: var(--arm-color-base, #1f2023);
  --ads-card-border-color-hover: var(--arm-orange, #ff6b00);
}

.dev-c-article-card ads-card-content {
  position: relative;
  --ads-card-content-padding: 1.5rem;
}

.dev-c-card-tag {
  --ads-tag-background-color: transparent;
  --ads-tag-border-color: var(--arm-dark-grey-alt, #5d6c7a);
  --ads-tag-border-color-focus: var(--arm-dark-grey-alt, #5d6c7a);
  --ads-tag-border-color-hover: var(--arm-dark-grey-alt, #5d6c7a);
  --ads-tag-color: var(--arm-dark-grey-alt, #5d6c7a);
  --ads-tag-color-focus: var(--white, #fff);
  --ads-tag-color-hover: var(--white, #fff);
  size: small;
  margin-right: 5px;
  position: static;
  --ads-tag-justify-content: left;
  max-width: 100%;
  i.product-facet-confidential {
    padding-left: 5px;
  }
}

html[theme="dark"] .dev-c-card-tag {
  --ads-tag-background-color: var(--arm-color-base, #1f2023);
  --ads-tag-background-color-focus: var(--arm-color-base, #1f2023);
  --ads-tag-background-color-hover: var(--arm-color-base, #1f2023);
  --ads-tag-border-color-focus: var(--arm-light-blue);
  --ads-tag-border-color-hover: var(--arm-light-blue);
  --ads-tag-color: #a3a8ae;
  --ads-tag-color-focus: var(--arm-light-blue);
  --ads-tag-color-hover: var(--arm-light-blue);
}

html[theme="dark"] .dev-c-card .dev-c-card__title {
  color: var(--arm-light-blue);
}

html[theme="dark"] .dev-c-card:focus .dev-c-card__title,
html[theme="dark"] .dev-c-card:hover .dev-c-card__title {
  color: var(--arm-orange);
}

.landing-b-card__title.dev-c-card__title {
  color: $arm-dark-blue;
  transition: color 0.3s ease-in-out;
  margin: 0.5em 0;
  font-size: 20px;

  // Break long strings up (e.g. filenames / pathnames)
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  // Restrict titles to 2 lines and add ellipses
  line-height: 1.6rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  /*! autoprefixer: ignore next */
  -webkit-box-orient: vertical;

  @media (max-width: 768px) {
    width: 16rem;
  }
  @media (min-width: 768px) {
    font-size: 20px;
  }
  .fa-external-link-alt {
    font-size: 0.5em;
    vertical-align: top;
  }
}
.dev-c-card .dev-c-card-tag-container {
  height: 30px;
  overflow: hidden;
  position: sticky;
  top: 150px;
}

.dev-c-feature-card ads-card-content .dev-c-card-tag-container {
  cursor: pointer;
  position: absolute;
  top: -3.5rem;
  left: 2rem;
}

.dev-c-feature-card-overlay ads-card-content .dev-c-card-tag-container {
  cursor: pointer;
  position: absolute;
  top: -3.5rem;
  left: 2rem;
  width: 500px;
  height: 400px;
}

@media (min-width: 992px) {
  .dev-c-feature-card-secondary {
    flex: 1 0 40%;
  }
}
@media (min-width: 1200px) {
  .dev-c-feature-card-secondary {
    flex: 1 0 30%;
  }
}
.dev-c-feature-card-container {
  flex-wrap: wrap;
  gap: 1rem;
}
.dev-c-feature-card-secondary {
  height: auto;
}

.publishdate {
  text-align: right;
  color: #a3a8ae;
  position: absolute;
  right: 10px;
  font-size: 14px;
  bottom: 5px;
}

.documents-card-recommendation {
  position: absolute;
  left: 26px;
  font-size: 14px;
  bottom: 5px;
}
.green {
  color: $light-green;
}
html[theme="dark"] .green {
  color: $light-green-dark-mode !important;
}

.contenttypetag {
  margin-bottom: 20px;
  position: absolute;
  bottom: 10%;
  transform: translateY(10%);
}
