@import "../Shared/colors.scss";
.coveo-list-layout {
  position: relative;
  border-bottom: 1px solid $light-white;
  padding: 0;
  .coveo-folding-footer {
    font-size: 15px;
  }
  .coveo-folding-footer-icon {
    color: inherit;
    font-size: 12px;
    margin-left: 5px;
  }
  .coveo-folding-footer.coveo-accessible-button {
    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
  .coveo-result-frame {
    margin-bottom: 10px;
    a {
      border-bottom: none;
    }
    .coveo-excerpt {
      display: inline;
      font-size: 16px;
      vertical-align: middle;
    }
    .fa-file-pdf {
      color: $arm-dark-blue;
      cursor: pointer;
      font-size: 19px;
      margin-right: 5px;
      margin-top: 4px;
      &:hover {
        color: $accessible-orange;
      }
    }
    .coveo-download-pdf {
      color: $arm-dark-blue;
      cursor: pointer;
      margin-top: 10px;
      margin-bottom: 3px;
      font-size: 16px;
      &:hover {
        color: $accessible-orange;
      }
      i {
        font-size: 14px;
      }
    }
    .coveo-result-row {
      margin: 0;
    }
    .coveo-result-row.initialRow {
      margin: 25px 0 0 0;
    }
    .coveo-result-row.titleRow {
      margin: 10px 0 10px 0;
      &:hover {
        .fa-file-pdf {
          color: $accessible-orange;
        }
        .coveo-result-link {
          pointer-events: auto;
          color: $accessible-orange;
        }
      }
      .coveo-result-cell {
        margin-right: 0;
      }
      .titleCell {
        display: inline;
      }
      .dark-theme-tooltip {
        display: none;
      }
      .light-theme-tooltip {
        display: inline;
      }
    }
    .coveo-result-cell {
      display: inline-block;
      margin-right: 7px;
      color: $dark-grey;
      .cardTitle {
        display: inline;
        margin: 15px 0 10px 0;
        .coveo-result-link,
        .coveo-result-link:visited {
          color: $arm-dark-blue;
          font-size: 22px;
          font-weight: 600 !important;
          line-height: 24px;
          span.coveo-highlight {
            font-size: 22px;
          }
        }
        :hover,
        .coveo-result-link:visited:hover {
          text-decoration: none;
          color: $accessible-orange;
          span {
            color: $accessible-orange;
          }
          &:after {
            border: none;
          }
        }
        .coveo-result-link:focus {
          text-decoration: none;
          color: $arm-dark-blue;
          span {
            color: $accessible-orange;
          }
          &:after {
            border: none;
          }
        }
      }
      .documentTagsContainer {
        height: 40px;
        overflow: hidden;
      }
      .documentType,
      .documentTags {
        border: 1px solid $off-white;
        border-radius: 5px;
        padding: 5px 10px;
        font-size: 14px;
        font-weight: bold;
        height: 30px;
        display: inline-block;
        span {
          text-decoration: none;
          color: $light-grey;
        }
        i {
          margin: 2px;
          margin-right: 4px;
          color: $light-grey;
        }
      }

      @media (max-width: 575px) {
        &.coveo-document-type {
          display: block;
          width: 100%;
        }
      }
      @media (max-width: 575px) {
        .documentType {
          display: inline-block;
        }
      }
      .documentType:hover,
      .documentTags:hover {
        border-color: $dark-grey;
        cursor: pointer;
        span {
          color: $dark-grey;
        }
        i {
          color: $dark-grey;
        }
      }
      .documentTags {
        margin: 10px 5px 10px 0;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
      }
      .newOld {
        display: inline-flex;
        font-weight: 600;
        font-size: 14px;
        .old-doc {
          margin-top: 6px;
        }
        .new-doc {
          margin-top: 6px;
        }
        .fa-lock-open {
          color: $black;
          font-size: 24px;
        }
      }
      &.date {
        position: absolute;
        right: 0;
        font-size: 14px;
        padding-top: 6px;
      }
      .smallGreyText {
        margin-bottom: 10px;
        font-size: 14px;
        color: $light-grey;
        a {
          margin-left: 3px;
          margin-right: 3px;
          font-size: 14px;
          color: $light-grey;
          cursor: pointer;
          &:hover {
            color: $accessible-orange !important;
            text-decoration: none;
          }
        }
      }
      .forum-status {
        font-size: 14px;
        margin-left: 10px;
        font-weight: bold;
      }
      .green {
        color: $light-green;
      }
      .grey {
        color: $light-grey;
      }
      .orange {
        color: $accessible-orange;
      }
      .coveo-excerpt {
        font-size: 16px;
        span.coveo-highlight {
          font-size: 16px;
        }
      }
    }
    &.coveo-folding-results {
      margin-top: 10px;
      padding-left: 15px;
      margin-bottom: 10px;
      .coveo-result-folding-child-result {
        border-left: 10px solid $light-white;
        margin-top: 2px;
        pointer-events: none;
        padding: 0 0 10px 10px;
        &:hover {
          border-left: 10px solid #c75300;
        }
        .coveo-result-link,
        .coveo-result-link:visited {
          font-size: 20px !important;
          span.coveo-highlight {
            font-size: 20px;
          }
        }
      }
    }
    .coveo-result-link {
      pointer-events: auto;
    }
  }
  .coveo-extra-info-row {
    font-size: 14px;
    margin-bottom: 10px;
    .coveo-extra-info-cell {
      float: right;
      .extra-margin-right {
        margin-right: 20px;
      }
      img {
        height: 25px;
        width: 25px;
        margin: 0 5px;
        border-radius: 15px;
        vertical-align: middle;
      }
    }
  }
  .clearFloat {
    clear: both;
  }
  .no-wrap {
    white-space: nowrap;
  }

  .fa-external-link-alt {
    font-size: 0.5em;
    vertical-align: top;
  }
  // .documents-recommendation {
  //   padding-left: 20px;
  // }
}

html[theme="dark"] {
  .coveo-result-frame .coveo-result-cell {
    color: $white-dark-mode;
    .documentType,
    .documentTags {
      border-color: $off-white-dark-mode;
      span {
        color: $grey;
      }
      i {
        color: $grey;
      }
    }
    .documentType:hover,
    .documentTags:hover {
      border-color: $arm-light-blue;
      cursor: pointer;
      span {
        color: $arm-light-blue;
      }
      i {
        color: $arm-light-blue;
      }
    }
    .newOld {
      .fa-lock-open {
        color: $white-dark-mode !important;
      }
    }
    .smallGreyText {
      a {
        color: $grey !important;
        &:hover {
          color: $hover-orange !important;
        }
      }
    }
    .green {
      color: $light-green-dark-mode !important;
    }
    .grey {
      color: $grey !important;
    }
    .orange {
      color: $hover-orange !important;
    }
    .coveo-result-link {
      color: $arm-light-blue !important;
      &:hover {
        color: $hover-orange !important;
      }
    }
    .fa-file-pdf {
      color: $arm-light-blue-dark-mode;
      &:hover {
        color: $hover-orange;
      }
    }
    .coveo-download-pdf {
      color: $arm-light-blue-dark-mode;
      &:hover {
        color: $hover-orange;
      }
    }
  }
  .cardTitle {
    .coveo-result-link {
      &:hover {
        color: $hover-orange !important;
        span {
          color: $hover-orange !important;
        }
      }
    }
  }
  .coveo-result-row.titleRow {
    .fa-file-pdf {
      color: $arm-light-blue !important;
    }
    &:hover {
      .fa-file-pdf {
        color: $hover-orange !important;
      }
      .coveo-result-link {
        color: $hover-orange !important;
        .coveo-highlight {
          color: $hover-orange !important;
        }
      }
    }
  }
  .coveo-folding-results .coveo-result-folding-child-result:hover {
    border-left: 10px solid $hover-orange !important;
  }
}
