@import "../Shared/colors";

.confirmBox {
  border: 8px solid rgba(0, 0, 0, 0.25);
  background-color: $white;
  padding: 1rem;
  display: flex;
}
.confirmBox p {
  font-size: 0.7rem;
  margin: 0;
}
.confirmBox button {
  font-size: 16px;
  padding: 0 10px;
  display: inline-block;
}
.textCenter {
  text-align: center;
}
.textCenterSmall {
  @media (max-width: 1023px) {
    text-align: center;
  }
}
.confirmBox .c-button .fa {
  margin-left: 0.5rem;
}

html[theme="dark"] {
  .confirmBox {
    p {
      color: $dark-grey;
    }

    button {
      background-color: $link-yellow;
      box-shadow: inset 0 0 0 var(--ads-button-border-width, 1px)
        $link-yellow;
      color: var(--ads-button-color-primary, $dark-grey);

      &:hover {
        background-color: $link-hover-yellow;
        box-shadow: inset 0 0 0 var(--ads-button-border-width-hover, 1px)
          $link-hover-yellow;
        color: var(--ads-button-color-primary-hover, $dark-grey);
      }
    }
  }
}
