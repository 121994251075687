@import "../Shared/colors.scss";

.coveo-sort-date-chevron {
    font-weight: 900;
    padding-left: 5px;
    color: $arm-dark-blue;
}

.coveo-results-header{
    .coveo-sort-section{
        display: flex;

        .CoveoSort{
            border-color:transparent;
            padding: 0 5px 2px;
            text-transform: none;
            font-size: 16px;
            font-weight: 400;
            display: flex;
            align-items: center;

        }
        .coveo-selected {
            border-bottom: 2px solid $dark-grey;
            padding-bottom: 0px;
        }
    }
}

html[theme='dark']{
    .coveo-sort-date-chevron {
        color: $arm-light-blue;
    }

    .coveo-results-header  {

        .coveo-sort-section {
            color: $white-dark-mode !important;
        }

        .CoveoSort.coveo-selected {
            border-bottom: 2.2px solid $white-dark-mode;
        }
    }
}
