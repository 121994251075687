.message-panel {
  margin-bottom: 0px;

  strong {
    font-size: 26px;
    font-weight: 400;    
  }

  .c-row{
    padding: 30px 0;
  }
}
