@import "../Shared/colors.scss";

.coveo-did-you-mean {
  min-height: 35px;
  padding: 0px 5px 10px 5px ;
  text-align: left;
  font-size: 16px;
  color: $black;
  display: flex;

  .corrected-word {
    font-size: 16px;
    color: $arm-dark-blue;
    padding-left: 3px;
    font-weight: bold; 
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

html[theme="dark"]{
  .coveo-did-you-mean {
    color: $white-dark-mode;

    .corrected-word {
      font-size: 16px;
      color: $arm-light-blue-dark-mode;
    }
  }
}
