.dev-c-key-resources__list li {
  margin: 0;
}

.dev-c-cta-link {
  --ads-cta-link-font-size: 16px;
}

@media screen and (min-width: 576px) {
  .dev-c-key-resources__list  {
    column-gap: 4rem;
    column-rule: 1px solid var(--arm-dark-grey-alt);
    columns: 2;
  }

  .dev-c-key-resources__list  li {
    break-inside: avoid; /* For standard browsers like IE. :-) */
    page-break-inside: avoid; /* For Firefox. */
    -webkit-column-break-inside: avoid; /* For Chrome & friends. */

    .c-cta-link {
      font-size: 16px;
    }

    a{
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}