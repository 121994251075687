.resultLink{
  font-size: 16px;
  break-inside: avoid;
  page-break-inside: avoid;

  .fa-lock-open {
    color: #1f0000;
  }

  a:hover {
    &:after {
      border-bottom-style: var(--ads-anchor-border-bottom-style-hover,dotted);
      border-bottom-width: var(--ads-anchor-border-bottom-width-hover,1px);
      border-color: inherit;
      display: block;
    }
  }
}

html[theme=dark] .resultLink .fa-lock-open {
  color: hsla(0, 0%, 100%, .9) !important;
}