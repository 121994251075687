$white: #FFFFFF;
$off-white: #E5ECEB;
$light-white: #c3c3c3;
$lighter-white :#F7F7f7;
$grey: #a3a8ae;
$off-grey: #7d868c;
$light-grey: #6f777c;
$dark-grey: #333e48;
$light-green: #5B8200;
$hover-orange: #ff6b00;
$breadcrumbs-orange: #ff6900;
$accessible-orange: #C75300;
$arm-dark-blue: #11809f;
$arm-light-blue: #00c1de;
$off-arm-blue: #047ca1;
$svg-dark-blue: #1d4f76;
$actionbar-white: #eff5f4;
$actionbar-off-white:#d4d5d5;
$action-bar-focus: #ccc;
$action-bar-bg-white: #FAFAFA;
$action-bar-off-black:#002b49;
$action-bar-off-grey:#aaa;
$action-bar-hr: #e9e9e9;
$actionbar-fas-off-white: #e2e7e7;
$visted-default-color: #0059b3;
$black: #1F0000;

//dark theme
$white-dark-mode: rgba(#fff, 0.9);
$dark-bg: #1f2023;
$arm-black: #383b40;
$arm-light-blue-dark-mode: #20c1dc;
$arm-dark-blue-dark-mode: rgb(17, 128, 159);
$arm-dark-grey-alt: #2d2f34;
$link-yellow: #ffc700;
$link-hover-yellow: #f6d452;
$light-green-dark-mode: #95d600;
$card-border: #53595d;
$card-background: #27292d;
$off-white-dark-mode: rgba(#E5ECEB,0.25);
$icon-dark-blue: #0d809f;