@import "../Shared/colors";

.dev-c-filter-checkboxes ads-checkbox {
  --ads-checkbox-margin-left: 0px;
}

.ellipsis
{   
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

.dev-c-show-more-less {
  --ads-button-background-color-tertiary: transparent;
  --ads-button-background-color-tertiary-focus: transparent;
  --ads-button-background-color-tertiary-hover: transparent;
  --ads-button-border-color-tertiary: transparent;
  --ads-button-border-color-tertiary-focus: transparent;
  --ads-button-border-color-tertiary-hover: transparent;
  --ads-button-color-tertiary: var(--arm-web-safe-blue);
  --ads-button-color-tertiary-focus: var(--arm-web-safe-orange);
  --ads-button-color-tertiary-hover: var(--arm-web-safe-orange);
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
}

html[theme='dark'] {
  .dev-c-filter-panel {
    --ads-expansion-panel-content-background-color: #1f2023;
    --ads-expansion-panel-content-border-color: var(--black);
    --ads-expansion-panel-toggle-border-color: var(--black);
  }
  .dev-c-show-more-less {
    --ads-button-color-tertiary: var(--arm-light-blue);
    --ads-button-color-tertiary-focus: var(--arm-orange);
    --ads-button-color-tertiary-hover: var(--arm-orange);
  }
  .clear-facets-btn {
    color: $arm-light-blue;
    &:hover {
      color: $hover-orange;
    }
  }
}

.clear-facets-btn {
  display: block;
  padding: 0;
  margin: 0;
  text-decoration: none;
  font-size: 16px;
  border: none;
  cursor: pointer;
  overflow: visible;
  width: auto;
  height: auto;
  box-shadow: none;
  color: $arm-dark-blue;

  &:hover {
    background-color: transparent;
    color: $accessible-orange;
    box-shadow: none;
  }

  &:focus {
    color: $arm-dark-blue;
    background-color: transparent;
    box-shadow: none;
  }
}

ul.u-padding-bottom-1\/2.ul_hover {
  margin-left: 0px;
}

ul.ul_hover {
  margin-left: -10px;
  &.margin_child {
    margin-left: -2px;
  }
}

.product-facet-confidential{
  font-size: small;
  position: relative;
  bottom: 0.1rem;
}