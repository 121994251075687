@import "../Shared/colors";

.global-nav-wrapper {
  top: 0;
}

.flyout-textlink {

  &:hover, &:focus {
    &:after {
      border: none;
    }
  }

  &:hover {
    h2 {
      color: $hover-orange !important;
    }
  }
}

.flyout-title:hover {
  color: $hover-orange !important;
}

.flyout-content-container {
  @media (min-width: 768px) and (max-width: 991px) {
    max-width: none;
  }
}

#mobile-search,
#account-flyout {
  height: 100vh;
  overflow-y: hidden;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;

  button {
    box-shadow: none;
  }
}

#account-flyout {
  padding: 15px;
  padding-top: 0;
  background-color: var(--ads-global-navigation-bar-background-color); 
}

.account-flyout-link {
  display: block;
}

.flyoutImage {
  height: 50px;
  width: 50px;
}

.c-flyout-card:focus-within .c-flyout-card__title,
.c-flyout-card:hover .c-flyout-card__title {
    color: var(--arm-orange) !important;
}

ads-global-navigation a {
  &:hover, &:focus {
    text-decoration: none;
  }
}

@media (max-width: 576px) {
  ads-masthead > ads-breadcrumbs {
    visibility: hidden!important;
  }
}
.c-contextual-navigation-wrapper a{
  text-decoration: none  !important;
}

.c-component a{
  text-decoration: none !important;
}
