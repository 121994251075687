@import "../Shared/colors.scss";

.dev-c-card__title {
  color: var(--arm-web-safe-blue, #11809f);
  transition: color .3s ease-in-out;
}

.dev-c-card:focus-within .dev-c-card__title,
.dev-c-card:hover .dev-c-card__title {
  color: var(--arm-web-safe-orange, #c75300);
}

html[theme='dark'] .dev-c-card .dev-c-card__title {
  color: var(--arm-light-blue, #00c1de);
}

html[theme='dark'] .dev-c-card:focus .dev-c-card__title,
html[theme='dark'] .dev-c-card:hover .dev-c-card__title {
  color: var(--arm-orange, #ff6b00);
}

/* Filter Cards */

.dev-c-filter-card {
  --ads-card-border-color: var(--arm-light-grey, #e5eceb);
  --ads-card-border-color-hover: var(--arm-web-safe-orange, #c75300);
  --ads-card-border-width: 1px;
  --ads-card-box-shadow-hover: 0 3px 6px 1px rgba(0,0,0,0.16);
}

html[theme='dark'] .dev-c-filter-card {
  --ads-card-background-color: var(--arm-color-base, #1f2023);
  --ads-card-background-color-hover: var(--arm-color-base, #1f2023);
  --ads-card-border-color: var(--black, #000);
  --ads-card-border-color-hover: var(--arm-orange, #ff6b00);
}

.dev-c-filter-card ads-card-content {
  position: relative;
}

.dev-c-filter-card ads-card-content::before {
  background-color: var(--arm-web-safe-blue, #11809f);
  content: '';
  height: calc(100% + 2px);
  left: -1px;
  position: absolute;
  top: -1px;
  transition: background-color;
  transition-duration: 0.3s;
  transition-timing-function: ease-in-out;
  width: 12px;
}

.dev-c-filter-card:focus-within ads-card-content::before,
.dev-c-filter-card:hover ads-card-content::before {
  background-color: var(--arm-web-safe-orange, #c75300);
}

html[theme='dark'] .dev-c-filter-card ads-card-content::before {
  background-color: var(--arm-blue);
}

html[theme='dark'] .dev-c-filter-card:focus-within ads-card-content::before,
html[theme='dark'] .dev-c-filter-card:hover ads-card-content::before {
  background-color: var(--arm-orange, #ff6b00);
}

.dev-c-filter-card__content {
  transform: translateX(0);
  transition: transform .25s ease-in;
}

.dev-c-filter-card:focus-within .dev-c-filter-card__content,
.dev-c-filter-card:hover .dev-c-filter-card__content {
  transform: translateX(3px);
}

.dev-c-filter-card__subtitle {
  display: block;
  font-size: 12px;
  line-height: 1em;
  margin-bottom: 0.5rem;
}

.dev-c-filter-card__title {
  font-size: 18px;
  line-height: 1em;
  margin-bottom: 0.5rem;
  // Break long strings up (e.g. filenames / pathnames)
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;

  // Restrict titles to 2 lines and add ellipses
  line-height: 1.6rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  .fa-external-link-alt
  {
    font-size: 0.5em;
    vertical-align: top;
  }
}

@media (min-width: 768px) {
  .dev-c-filter-card__title {
    font-size: 20px;
  }
}

.dev-c-filter-card__description {
  font-size: 14px;

  // Restrict description to 4 lines and add ellipses
  line-height: 1.4rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  height: 90px;
}
.documents-card-recommendation-landing-a {
  position: absolute;
  left: 32px;
  font-size: 14px;
  bottom: 5px;
}
.green {
  color: $light-green;
}


