 @import "../../components/Shared/colors.scss";
 @import "../../components/Shared/shared.scss";

 html[theme="dark"] {
    %darkBg {
        background-color: #383B40 !important;
    }
}

 .margin_left{
     margin-left: 20px; 
}

 .ul_hover{
    &:hover {
        @extend %darkBg;
    }
 }

.instruction_chevron {
    color: $icon-dark-blue;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    margin-top: 5px;
    margin-right: 5px;
    &:hover {
        color: #c75300;
    }
}

.nested{
    padding-left: 10px !important;
    display: none;
}

.active{
    display: block;
}

.pointers{
    cursor: pointer;
}
