@import "../Shared/colors.scss";
.breadcrumb{
  margin: 5px 0;
  display: flex;
  .breadcrumb-title{
    font-size: 16px;
    color: $light-grey;
    margin-right:8px;
  }
  .breadcrumb-items{
    flex-grow: 1;
    .breadcrumb-item {
      margin: 5px 0;
      a::after {
        border-bottom: none;
      }
    }
  }
  .clear-filter{
    color: $arm-dark-blue;
    cursor: pointer;
    font-size: 1rem;
    flex-shrink: 0;
    &:hover {
      text-decoration: none;
      color: $accessible-orange;
    }
  }
  .circle-style {
    font-size: 16px;
    margin-left: 5px;
  }
  .breadcrumb-value{
    border: 1px solid $off-white;
    border-radius: 5px;
    font-size: 16px;
    color: $dark-grey;
    font-family: 'Lato', 'Helvetica', 'Arial', 'sans-serif';
    height: 30px;
    padding: 0px 6px 2px 6px;
    margin: 2px;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    &:hover {
      background-color: $lighter-white;
      border: 1px solid $arm-dark-blue;
      color: $arm-dark-blue;
    }
  }
  @media (max-width: 991px) {
    .breadcrumb-value {
      color: $arm-dark-blue;
      height: auto;
    }
  }
}
html[theme='dark']{
  .breadcrumb{
    .breadcrumb-title {
      color: $grey;
    }
    .breadcrumb-value {
      color: $white;
      border:  1px solid rgba(#ffffff, 0.25);
      background-color: rgba(#333e48, 0.5);
      &:hover {
        color: $arm-light-blue;
        border: 1px solid $arm-light-blue;
        background-color: $dark-grey;
      }
    }
    .clear-filter{
      color: $arm-light-blue;
      &:hover {
        text-decoration: none;
        color: $hover-orange;
        border-bottom: none;
      }
    }
    @media (max-width: 991px) {
      .breadcrumb-value {
        color: $arm-light-blue;
        background-color: $dark-grey;
        border: 1px solid rgba(#ffffff, 0.25);
      }
    }
   
  }

}
