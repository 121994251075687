.ceRelatedItems {
  font-family: 'Lato', 'Helvetica', 'Arial', 'sans-serif';
  font-size: 18px;

  button {
    font-family: inherit;
    cursor: pointer;
    border: none;
    background: transparent;
    display: block;

    &:focus {
      outline:0;
    }
  }

  &Toggle {
    width: auto;
    height: auto;
    overflow: visible;
    transition: visibility 0.25s, opacity 0.25s linear;
    visibility: visible;
    opacity: 1;
    margin: 0;
    padding: 0;
    text-align: left;
    font-size: inherit;

    span {
      text-align: center;
      display: block;
      font-family: inherit;
      font-size: inherit;
    }

    .expanderArrows {
      border-radius: 30em;
      width: 56px;
      margin-left: 0;
      background-color: #002b49;
      color: #fff;
      font-size: 26px;
      float: left;
      height: 56px;
      line-height: 56px;
    }

    .expanderText {
      width: 180px;
      padding: 0 8px;
      margin: 4px 4px 0 4px;
      background-color: #fff;
      border: 1px solid #e5eceb;
      border-radius: 30em 15em 15em 30em;
      box-shadow: 2px 2px 2px #7d868c;
      height: 44px;
      line-height: 46px;
      font-size: 14px;

      strong {
        color: #11809f;
        font-weight: 500;
      }
    }
  }

  &Contents {
    border-radius: 1em;
    box-shadow: 2px 2px 2px #5d6c7a;
    padding: 10px 20px;
    visibility: hidden;
    opacity: 0;
    background-color: #46535f;
    color: #FFF;
    height: 0;
    width: 0;
    overflow: hidden;
    box-sizing: border-box;

    .relatedGroup {
      border-bottom: 1px solid #7d868c;

      &:last-child {
        border-bottom: none;
      }

      .viewMore {
        align-items: center;
        border-bottom: none;
        display: inline-flex;
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 20px;

        &:after {
          border-right: 2px solid #ffc700;
          border-top: 2px solid #ffc700;
          content: "";
          height: 16px;
          margin-left: 90px;
          margin-bottom: 7px;
          width: 16px;
          display: block;
          transform: rotate(45deg);
        }
      }
    }

    h3 {
      font-size: 22px;
      font-weight: 400;
      color: inherit;
      margin: 1em 0 0.5em 0;
      line-height: initial;

      i {
        font-size: 26px;
        margin-right: 10px;
      }
    }

    a {
      color: #f7f7f7;
      font-size: 18px;
      border-bottom: none;
      text-decoration: none;

      &:hover {
        color: #ff6b00;
        border-bottom: 1px dotted;
      }
    }

    ul {
      line-height: 1.5;
      list-style-type: none;
      margin-bottom: 15px;
      margin-left: -15px;
      padding: 0 0 0 15px;
      position: relative;
    }

    li {
      margin-bottom: 9px;
      margin-left: 30px;
      list-style-position: inside;

      &::before {
        content: '\2022\ ';
        left: 20px;
        position: absolute;
        color: #7d868c;
        font-size: 22px;
      }
    }

    .relatedTitle {
      padding-bottom: 10px;
      border-bottom: 1px solid #fff;
    }

    .close {
      float: right;
      margin: 0;
      color: inherit;
      padding: 0;

      i {
        font-size: 24px;
        font-weight: 300;
      }
    }
  }

  .collapsed {
    visibility: hidden;
    display: none;
    opacity: 0;
    height: 0;
    overflow: hidden;
  }

  .expanded {
    width: 300px;
    visibility: visible;
    display: block;
    opacity: 1;
    transition: visibility 0.5s, opacity 0.5s linear;
    height: auto;
    overflow: visible;
  }
}

.ceRelatedItemsDarkTheme {
  .ceRelatedItemsToggle {
    .expanderArrows{
      color: #333e48;
      background-color: #00c1de;
    }
    strong {
      color: #333e48;
      font-weight: 600;
    }
  }

  .ceRelatedItemsContents {
    background-color: #fff;
    border: 1px solid #e5eceb;
    color: #333e48;

    a {
      color: #11809f;

      &:hover {
        color: #ff6b00;
      }
    }

    .relatedTitle {
      border-bottom-color: #333e48;
    }
  }
}

:global(.dark-theme){
  .ceRelatedItemsToggle{
    .expanderArrows{
      background-color: #00c1de;
      color: rgba(#fff, 0.9);
    }
    .expanderText{
      background-color: #333e48;
      box-shadow: none;
      border: 1px solid #27292d;
      border-color: #45535f;
      color: rgba(#fff, 0.9);

      strong{
        color: #00c1de;
      }
    }
  }
}
