@import "../Shared/colors.scss";

.coveo-results-header {
  .coveo-summary-section{
    .coveo-query-summary{
      color: $dark-grey;
      font-size: 16px;

      .cancel-last{
        font-size: 16px;
        color: $arm-dark-blue;
        margin-top: 25px;
        margin-bottom: 25px !important;
      }

      .cancel-last:hover {
        color: $accessible-orange;
      }

      .no-results-string{
        font-size: 18px;
      }

      .search-tips-info{
        font-size: 16px;
        color: $dark-grey;

        ul {
          margin-top: 5px;
        }
      }
    }
    .coveo-highlight{
      color: $dark-grey;
    }

  }
}
.bold-text{
  font-size: 18px;
  font-weight: 600;
  color: $dark-grey
}

html[theme="dark"]{
  .bold-text{
    color: $white-dark-mode
  }
  .coveo-results-header {
    .coveo-summary-section{
      .coveo-query-summary{
        color: $white-dark-mode;

        .cancel-last{
          color: $arm-light-blue;
        }
        .cancel-last:hover {
          color: $hover-orange;
        }

        .search-tips-info{
          color: $white-dark-mode;
        }
      }
      .coveo-highlight{
        color: $white-dark-mode;
      }
    }
  }
}
