@import "../Shared/colors.scss";

.coveo-accessible-button {
  cursor: pointer;
}

.c-col {
  @media(max-width: 768px){
    padding: 0;
  }
}

.result-column .c-col.c-col-12{
  max-width: 1335px;
  display: inline-block;
}

@media (min-width: 992px) {
  .result-column{
    width: 70%;
    padding: 0;
  }
}

.search-result {
  max-width: 1335px;
  .searchResultHeader{
    text-align: left;
    font-weight: 600;
    font-size: 24px;
    color:$dark-grey;
    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
    @media (max-width: 768px) {
      display: none;
    }
  }
}

.search-result-cards {
  max-width: 100%;
}

@media (max-width: 992px) {
  #facetsContainer {
    position: absolute;
    z-index: 5;
  }
}

.coveo-results-header {
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  .coveo-summary-section{
    font-size: 16px;
    display: inline-block;
  }

  @media (max-width: 480px) {
    display: block;
    .coveo-summary-section {
      width: 100%;
      margin-bottom: 10px;
      display: block;
      .coveo-query-summary {
        display: block;
      }
    }
  }
}

.coveo-headless-accessible-button {
  &:hover {
    color: $accessible-orange !important;
    cursor: pointer;
  }
}

html[theme="dark"] {
  .searchResultHeader{
    color: $white-dark-mode
  } 
  .coveo-accessible-button {
    &:hover {
      color: $hover-orange !important;
    }
  }
 
}

.dev-c-filter-button {
  --ads-button-font-size-large: 24px;
  height: 44px;
  width: 64px;
  margin-left: 1rem;
}

.dev-c-filter-backdrop {
  background-color: #000000bf;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}


.dev-c-filter-button.is-open {
  z-index: 5;
}

.dev-c-tab-heading {
  border-style: solid;
  border-width: 1px;
  border-color: var(--arm-light-grey, #e5eceb);
  min-height: 79px;
  padding: 1rem;
}

html[theme='dark'] .dev-c-tab-heading {
  background-color: var(--arm-color-surface, #27292d);
  border-color: var(--black, #000);
}

@media (min-width: 992px) {
  .dev-c-tab-heading {
    border: none;
    min-height: unset;
    padding: 0;
  }

  html[theme='dark'] .dev-c-tab-heading {
    background-color: transparent;
  }
}

.dev-c-tab-heading.dev-c-tab-filter-heading {
  grid-template-columns: auto 64px;
}

@media (min-width: 992px) {
  .lg\:u-show{
    display: block!important;
  }
}

@media screen and (min-width: 576px) {
  .dev-c-link-grid  {
    column-gap: 4rem;
    columns: 2;
    max-width: 800px;
  }

  .dev-c-link-grid > li {
    break-inside: avoid; /* For standard browsers like IE. :-) */
    page-break-inside: avoid; /* For Firefox. */
    -webkit-column-break-inside: avoid; /* For Chrome & friends. */
  }
}
span.fal {
  padding: 5px 5px 2px;
  margin-right: 5px;
}

  
.toggleicon {
  margin-right: 7px;
  
}
.toggleicon.coveo-selected {
  border-bottom: 3px solid;
  padding-bottom: 1.5px;
}