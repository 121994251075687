@import "../../../Shared/shared.scss";

.dev-c-card--home {
  --ads-card-background-color: var(--white, #fff);
  --ads-card-background-color-hover: var(--white, #fff);
  --ads-card-border-color: var(--arm-light-grey, #e5eceb);
  --ads-card-border-color-hover: var(--arm-web-safe-orange, #c75300);
  --ads-card-border-radius: 10px;
  --ads-card-border-width: 1px;
  --ads-card-box-shadow-hover: 0 3px 6px 1px rgba(0,0,0,0.16);

  .dev-c-card__title {
    color: var(--arm-web-safe-blue, #11809f);
    transition: color .3s ease-in-out;
    line-height: 1.8rem;
  }

  .dev-c-card__author {
    font-size: 14.4px;
    font-weight: 400;
    display: inline;
    line-height: 1rem;
  }
  
  html[theme='dark'] .dev-c-card .dev-c-card__title {
    color: var(--arm-light-blue);
  }
  
  html[theme='dark'] .dev-c-card:focus .dev-c-card__title,
  html[theme='dark'] .dev-c-card:hover .dev-c-card__title {
    color: var(--arm-orange);
  }

  p {  
    // Restrict description to 6 lines and add ellipses
    font-size: 1rem;
    line-height: 1.5rem;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
  }
  .fa-external-link-alt
  {
    font-size: 0.5em;
    vertical-align: top;
  }
}

.placeholder-container{
  height: 250px;
  width: 100%;
}

html[theme='dark'] .dev-c-card.dev-c-card--home {
  --ads-card-background-color: var(--arm-color-surface, #27292d);
  --ads-card-background-color-hover: var(--arm-color-surface, #27292d);
  --ads-card-border-color: var(--black, #000);
}

@media (min-width: 992px) {
  .lg\:u-display-block {
      display: block!important;
  }
}

.dev-c-card-tag {
  --ads-tag-background-color: transparent;
  --ads-tag-border-color: var(--arm-dark-grey-alt, #5d6c7a);
  --ads-tag-border-color-focus: var(--arm-dark-grey-alt, #5d6c7a);
  --ads-tag-border-color-hover: var(--arm-dark-grey-alt, #5d6c7a);
  --ads-tag-color: var(--arm-dark-grey-alt, #5d6c7a);
  --ads-tag-color-focus: var(--white, #fff);
  --ads-tag-color-hover: var(--white, #fff);
  size: small;
}

html[theme='dark'] .dev-c-card-tag {
  --ads-tag-background-color: var(--arm-color-base, #1f2023);
  --ads-tag-background-color-focus:var(--arm-color-base, #1f2023);
  --ads-tag-background-color-hover: var(--arm-color-base, #1f2023);
  --ads-tag-border-color-focus: var(--arm-light-blue);
  --ads-tag-border-color-hover: var(--arm-light-blue);
  --ads-tag-color: #a3a8ae;
  --ads-tag-color-focus: var(--arm-light-blue);
  --ads-tag-color-hover: var(--arm-light-blue);
}

